import React from "react"
import { Container } from "theme-ui"
import { Layout, SEO } from "../components"

const ThankYou = () => (
  <Layout>
    <SEO title="Thank You" />
    <Container px={3} py={[4, 5, 100]} css="text-align: center;">
      <h1>Thank you!</h1>
      <p>Your form submission has been received.</p>
    </Container>
  </Layout>
)

export default ThankYou
